<template>
  <div class="columns is-multiline" v-if="show">
    <template v-if="element.input_type === 'CHECKBOX'">
      <div class="column is-12">
        <b-field>
          <b-checkbox v-model="element.selectedValue"
            >{{ element.name }}
          </b-checkbox>
        </b-field>
      </div>
      <template v-if="element.sub_elements">
        <div class="column is-12">
          <survey-element
            v-for="(subElement, i_sub) in element.sub_elements"
            :key="i_sub"
            :element="subElement"
            :show="subElement.show_if === element.selectedValue"
          />
        </div>
      </template>
    </template>
    <template v-else-if="element.input_type === 'TEXTLINE'">
      <div class="column is-12">
        <b-field :label="element.name">
          <b-input v-model="element.selectedValue"></b-input>
        </b-field>
      </div>
    </template>
    <template v-else-if="element.input_type === 'TEXTAREA'">
      <div class="column is-12">
        <b-field :label="element.name">
          <b-input v-model="element.selectedValue" type="textarea"></b-input>
        </b-field>
      </div>
    </template>
    <template v-else-if="element.input_type === 'HORIZONTAL_RADIO'">
      <div class="column is-12">
        <b-field :label="element.name">
          <b-radio-button
            v-model="element.selectedValue"
            :native-value="value.value"
            type="is-primary"
            v-for="(value, iv) in element.values"
            :key="iv"
          >
            <span>{{ value.name }}</span>
          </b-radio-button>
        </b-field>
      </div>
    </template>
    <template v-else-if="element.component_type === 'paragraph'">
      <div class="column is-12">
        <p>{{ element.value }}</p>
      </div>
    </template>
    <template v-else>{{ element.input_type }}: {{ element.name }}</template>
  </div>
</template>

<script>
export default {
  name: "SurveyElement",
  components: {},
  data() {
    return {};
  },
  props: {
    element: {
      type: Object,
      default: null,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  mounted(){
  }
};
</script>

<style></style>
