import Vue from "vue";
import Vuex from "vuex";
import VueRouter from "vue-router";
import App from "./App.vue";
import Buefy from "buefy";
import { store } from "./store/store.js";
import Home from "./components/Home";
import Imprint from "./components/Imprint";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Survey from "./components/Survey";
import Completion from "./components/Completion";
import VueQRCodeComponent from "vue-qrcode-component";

Vue.use(Buefy);

Vue.use(Vuex);
Vue.use(VueRouter);

Vue.component("qr-code", VueQRCodeComponent);

Vue.config.productionTip = false;

export const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/surveys/:id",
      name: "surveys",
      component: Survey,
      meta: {
        authRequired: false,
      },
    },
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        authRequired: false,
      },
    },
    {
      path: "/completion",
      name: "completion",
      component: Completion,
      meta: {
        authRequired: false,
      },
    },
    {
      path: "/imprint",
      name: "imprint",
      component: Imprint,
      meta: {
        authRequired: false,
      },
    },
    {
      path: "/privacy",
      name: "privacy",
      component: PrivacyPolicy,
      meta: {
        authRequired: false,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const authRequired = to.meta.authRequired;
  const loggedIn = localStorage.getItem("user");

  if (authRequired && !loggedIn) {
    return next("/login");
  }

  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
