<template>
  <section class="mt-3 mb-3">
    <div class="container">
      <h1 class="title">Vielen Dank für Ihre Teilnahme!</h1>
    </div>
  </section>
</template>

<script>
export default {
  name: "Home",
  components: { },
  data() {
    return {
      
    };
  },
  methods: {
    init() {
      
    }
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
