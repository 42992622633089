<template>
  <b-navbar wrapper-class="container">
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img src="https://ticketingsolutions.canto.global/direct/image/uh2mcvas5h63h31lbl8khtm17t/rUw5hP7AokkMaCsDh3rkiW0P_3g/m800/800" class="brand-logo" />
      </b-navbar-item>
    </template>
    <template #start>
    </template>
  </b-navbar>
</template>

<script>
export default {
  name: "BaseHeader",
};
</script>

<style>
.navbar-item img {
  max-height: 5rem !important;
}
</style>
