<template>
  <section class="mt-3 mb-3">
    <div class="container" v-if="survey._id != undefined">
      <h1 class="title">{{ survey.title }}</h1>
      <section v-for="(step, index) in survey.steps" :key="index">
        <survey-element
          v-for="(element, i) in step.elements"
          :key="i"
          :element="element"
        />
      </section>
      <section>
        <div class="columns">
          <div class="column">
            <b-button
              type="is-primary"
              @click="finishSurvey"
              >Absenden</b-button
            >
          </div>
        </div>
      </section>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </section>
</template>

<script>
import SurveyElement from "./SurveyElement.vue";
export default {
  name: "Home",
  components: { SurveyElement },
  data() {
    return {
      surveyId: this.$route.params.id,
      survey: {},
      isLoading: false,
    };
  },
  computed: {
    surveyCompleted() {
      let somethingMissing = false;
      this.survey.steps.forEach((step) => {
        step.elements.forEach((element) => {
          if (element.required) {
            if (
              element.selectedValue == undefined ||
              element.selectedValue == null ||
              element.selectedValue == ''
            ) {
              if(element.input_type !== 'CHECKBOX'){
                somethingMissing = true;
              } 
            }
            if (element.sub_elements) {
              element.sub_elements.forEach((subElement) => {
                if (subElement.required && subElement.show_if == element.selectedValue) {
                  if (
                    subElement.selectedValue == undefined ||
                    subElement.selectedValue == null ||
                    subElement.selectedValue == ''
                  ) {
                    somethingMissing = true;
                  }
                }
              });
            }
          }
        });
      });

      return !somethingMissing;
    },
  },
  methods: {
    init() {
      this.isLoading = true;
      this.$store.getters.axios
        .get(this.$store.getters.apiUrl + "/surveys/" + this.surveyId)
        .then((response) => {
          this.survey = response.data;
          this.survey.begin = new Date();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    finishSurvey() {
      let completed = this.surveyCompleted;

      if (!completed) {
        this.$buefy.toast.open({
          duration: 5000,
          message: `Bitte füllen Sie alle erforderlichen Felder aus!`,
          position: "is-bottom",
          type: "is-danger",
        });
        return;
      }

      this.survey.end = new Date();

      let result = {
        'survey_id': this.surveyId,
        'begin': this.survey.begin,
        'end': this.survey.end
      }

      this.survey.steps.forEach((step) => {
        step.elements.forEach((element) => {

          if(element.input_type == 'CHECKBOX'){
            if(element.selectedValue == undefined || element.selectedValue == null){
              element.selectedValue = false;
            }
          }
            
          if(element.input_type !== undefined)
            result[element.key] = element.selectedValue;
          
          if(element.sub_elements){
            element.sub_elements.forEach((subElement) => {
              if(subElement.input_type !== undefined)
                result[subElement.key] = subElement.selectedValue;
            })
          }
        })
      })

      this.$store.getters.axios.post(this.$store.getters.apiUrl + '/result', result).then(() => {
        this.$router.push({ path: '/completion' });
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
