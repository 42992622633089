import Vue from "vue";
import Vuex from "vuex";

import { authentication } from "./authentication.module";
//import { bearerToken } from "../_helpers";

Vue.use(Vuex);
const axios = require("axios");

export const store = new Vuex.Store({
  state: {
    apiUrl: process.env.VUE_APP_BACKEND_URL,
    axios: axios.create()
  },
  mutations: {},
  getters: {
    apiUrl: (state) => state.apiUrl,
    axios: (state) => state.axios,
    user: (state) => state.authentication.user,
  },
  actions: {},
  modules: {
    authentication,
  },
});

/*store.state.axios.interceptors.request.use((config) => {
  config.headers["Authorization"] = bearerToken();
  return config;
});

store.state.axios.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling service
    return response;
  },
  (error) => {
    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    store.dispatch("authentication/logout");

    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
);*/
